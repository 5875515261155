import React, { useState, useEffect, useRef, useMemo } from "react";
import { useMedia } from "react-use";
import SettingNodataD from '../../assets/images/setting_nodata_d.png'
import SettingNodataL from '../../assets/images/setting_nodata_l.png'
import Close from '../../pages/crossChain/images/close.png'
import Closel from '../../pages/crossChain/images/close_l.png'
import CrossModalBack from '../../assets/images/cross_modal_back.png'
import CrossModalBackLight from '../../assets/images/cross_modal_back_light.png'
import Search from '../../pages/crossChain/images/search.png'
import SearchL from '../../pages/crossChain/images/search_l.png'
import CheckDuiDios from '../../assets/images/check_dui_dios.png'
import CheckDuiAct from '../../assets/images/check_dui.png'
import EthImg from '../../assets/images/eth.png'
import { Switch } from "antd";
import TransactionTd from './components/TransactionTd'
import { utils, providers, Wallet } from 'ethers'
import { NETWORK_METADATA } from '../../config/chains'
import { useSelector, useDispatch } from "react-redux";
import "./index.scss";
import { useTranslation } from "react-i18next";
import { useWeb3React } from "@web3-react/core";
import useWeb3ReactDeriw from "../../utils/wallets/useWeb3ReactDeriw";
import httpApi from "../../api";
import { formatAmount } from "../../utils/numbers";
import dayjs from "dayjs";


// e.stopPropagation()
const SettingsModal = ({ onClose }) => {
  
  const web3param = useWeb3ReactDeriw();
  const { library, account, chainId } = web3param;
  const mode = useSelector(state => state.mode);
  const [tab, setTab] = useState(1)
  const { t } = useTranslation();
  const below960 = useMedia("(max-width: 960px)");

  const refNetworks = useRef({})
  const refNetworkLayers = useRef({})
  const [transactions, setTransactions] = useState([])
  const [tokenInfo, setTokenInfo] = useState([])

  useEffect(() => {
    if (account) {
      getHistory(tab)
    }
  }, [account])

  const updateTab = (n) => {
    setTab(n)
    getHistory(n)
  }

  const [searchInput, setSearchInput] = useState('')

  const [coinList, setCoinList] = useState()
  useEffect(() => {
    if (transactions && transactions.length) {
      const filterList = transactions.filter(item => item.from_txhash.toLocaleLowerCase().indexOf(searchInput.toLocaleLowerCase()) > -1)
      setCoinList(filterList)
    }
  }, [searchInput])
  const [pageLoading, setPageLoading] = useState(false)

  const getHistory = async (tab) => {
    let ti = {}
    if (!tokenInfo[1]) {
      const res = await httpApi.getCrossChain()
      if (res && res.data) {
        const resTi = {
          1: {
            layer: 1,
            symbol: res.data.symbol,
            decimal: res.data.decimal,
            token: res.data.l1_token_address,
            chainId: res.data.l1_token_chain_id
          },
          2: {
            layer: 2,
            symbol: res.data.symbol,
            decimal: res.data.decimal,
            token: res.data.l2_token_address,
            chainId: res.data.l2_token_chain_id
          },
          3: {
            layer: 3,
            symbol: res.data.symbol,
            decimal: res.data.decimal,
            token: res.data.l3_token_address,
            chainId: res.data.l3_token_chain_id
          },

          [res.data.l1_token_chain_id]: {
            layer: 1,
            symbol: res.data.symbol,
            decimal: res.data.decimal,
            token: res.data.l1_token_address,
            chainId: res.data.l1_token_chain_id
          },
          [res.data.l2_token_chain_id]: {
            layer: 2,
            symbol: res.data.symbol,
            decimal: res.data.decimal,
            token: res.data.l2_token_address,
            chainId: res.data.l2_token_chain_id
          },
          [res.data.l3_token_chain_id]: {
            layer: 3,
            symbol: res.data.symbol,
            decimal: res.data.decimal,
            token: res.data.l3_token_address,
            chainId: res.data.l3_token_chain_id
          }
        }
        ti = resTi
        setTokenInfo(resTi)
      }
    }

    if (!account) {
      return
    }
    setPageLoading(true)

    const tp = ti[1] ? ti : tokenInfo

    const tabParams = {
      1: {
        from_chain_Id: tp[2].chainId,
        to_chain_Id: tp[3].chainId,
      },
      2: {
        from_chain_Id: tp[3].chainId,
        to_chain_Id: tp[2].chainId,
      },
      3: {
        from_chain_Id: tp[1].chainId,
        to_chain_Id: tp[3].chainId,
      },
      4: {
        from_chain_Id: tp[3].chainId,
        to_chain_Id: tp[1].chainId,
      }
    }
    const res = await httpApi.getBridgeminiTransactions({
      from_user_address: account.toLowerCase(),
      ...tabParams[tab],
      order: 2
    })
    if (res && res.data && res.data.list) {
      let _res = []
      for (let i = 0; i < res.data.list.length; i++) {
        const instance = res.data.list[i]
        _res.push({
          ...instance,
          decimals: tp[instance.FromChainId].decimal,
          symbol: tp[instance.FromChainId].symbol
        })
      }
      setTransactions(_res)
      setCoinList(_res)
    } else {
      setTransactions([])
      setCoinList([])
    }

    setPageLoading(false)
  }


  const [pendingWithdrawal, setPendingWithdrawal] = useState({})

  return (
    <>
      <div className="SettingsModal_mask" onClick={onClose}></div>
      <div className={["SettingsModal"].join(' ')}>

        <img className="SettingsModal_close" onClick={onClose} src={
          below960 ?
            mode.mode == 'dark' ? CrossModalBack : CrossModalBackLight :
            mode.mode == 'dark' ? Close : Closel
        } />

        <div className="SettingsModal_title">
          {t('Transaction History')}
        </div>
        <div className="SettingsModal_text1">{t('Fetching transaction history details might be slower for Deriw chains.')}</div>
        <div className="SettingsModal_tab_box">
          <div onClick={() => updateTab(1)} className={["SettingsModal_tab", tab == 1 && 'SettingsModal_tab_act'].join(' ')}>{t('To')} Deriw Testent</div>
          <div onClick={() => updateTab(2)} className={["SettingsModal_tab", tab == 2 && 'SettingsModal_tab_act'].join(' ')}>{t('To')} Ethereum</div>
          {/* <div onClick={() => updateTab(3)} className={["SettingsModal_tab", tab == 3 && 'SettingsModal_tab_act'].join(' ')}>l1 to l3</div> */}
          {/* <div onClick={() => updateTab(4)} className={["SettingsModal_tab", tab == 4 && 'SettingsModal_tab_act'].join(' ')}>l3 to l1</div> */}
        </div>
        <div className="SettingsModal_search">
          <img src={mode.mode == 'dark' ? Search : SearchL} />
          <input placeholder={t('搜索')} value={searchInput} onChange={(e) => setSearchInput(e.target.value)} />
        </div>
        <div className="SettingsModal_table_head">
          <div className="SettingsModal_table_head_item">{t('状态')}</div>
          <div className="SettingsModal_table_head_item SettingsModal_table_td_item1">{t('时间')}</div>
          <div className="SettingsModal_table_head_item SettingsModal_table_td_item2">{t('earn_token')}</div>
          <div className="SettingsModal_table_head_item SettingsModal_table_td_item3">{t('Cross_from')}</div>
          <div className="SettingsModal_table_head_item SettingsModal_table_td_item3">{t('Cross_to')}</div>
          <div className="SettingsModal_table_head_item SettingsModal_table_td_item4">{t('counting down')}</div>
          <div className="SettingsModal_table_head_item SettingsModal_table_td_item5"></div>
        </div>
        {/* {
          transactions.map((transaction, index) => {
            return (
              <div key={"transaction_" + index} className="SettingsModal_table_head">
                <div className="SettingsModal_table_head_item" onClick={() => claim(transaction)}>{t(transaction.status)} {pendingWithdrawal[transaction.l2_tx_hash_2]?.status == "Confirmed" ? "Claim" : ""}</div>
                <div className="SettingsModal_table_head_item">{dayjs(transaction.created_at * 1000).format('D/M/YYYY h:m:s A')}</div>
                <div className="SettingsModal_table_head_item">{formatAmount(transaction.from_token_amount, transaction.decimals, 2)}{" "}{transaction.symbol}</div>
                <div className="SettingsModal_table_head_item">{transaction.from_tx_hash}</div>
              </div>
            )
          })
        } */}

        <div className="SettingsModal_table_head_box">
          {
            pageLoading ?
              <div className="SettingsModal_table_nodata">
                <img src={mode.mode == 'dark' ? SettingNodataD : SettingNodataL} />
                {t('加载中')}
              </div> :
              coinList && coinList.length ?
                coinList.map((transaction, index) => {
                  return (
                    <TransactionTd
                      key={"transaction_" + index}
                      transaction={transaction}
                      tab={tab}
                      getHistory={getHistory}
                      refNetworks={refNetworks}
                      refNetworkLayers={refNetworkLayers}
                      pendingWithdrawal={pendingWithdrawal}
                      setPendingWithdrawal={setPendingWithdrawal}
                    />
                  )
                })
                :
                <div className="SettingsModal_table_nodata">
                  <img src={mode.mode == 'dark' ? SettingNodataD : SettingNodataL} />
                  {t('no information')}
                </div>
          }
        </div>


      </div>
    </>
  );
};

export default SettingsModal;
