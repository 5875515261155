import { ethers } from "ethers";
const { parseEther } = ethers.utils;

export default {
  networkId: 8449, // 1:主网, 3：Ropsten测试链, 128: 火币主网,256: 火币测试链 42：kovan测试
  baseUrl: "https://testgmxapi.weequan.cyou",
  testTransactionUrl: "https://dev.deriw.com/trading-prop/trade",
  deriwTransactionUrl: "https://www.deriw.com/trading-prop/trade",

  aboutFundPoolDetailUrl: "https://docs.deriw.com/liquidity/liquidity-on-v1",
  klineUrl: "https://chart.test.deriw.com/",
  duneUrl: "https://dune.com/deriw_com/deriw-analytics",
  wsUrl: "wss://testgmxapi.weequan.cyou/ws",
  explorer: "https://explorer.dev.deriw.com/",
  tx: 'https://explorer.dev.deriw.com/tx/',
  deriwExplorer: "https://explorer.deriw.com/",
  deriwTx: 'https://explorer.deriw.com/tx/',
  tokenAssetBaseUrl: "https://oss.deriw.com/tokens/",
  shareBaseUrl: "https://fh-dex.oss-ap-southeast-1.aliyuncs.com/fh-dex/client/invitation/",

  telegramgb: "https://t.me/deriwofficial",
  telegram: "https://t.me/deriwfinance",
  twitter: "https://twitter.com/DeriwFi",
  medium: "https://medium.com/@DeriwFi",
  discord: "https://discord.com/invite/deriwfinance",
  coinmarketcap: "https://coinmarketcap.com/community/profile/Deriw/",
  github: "https://github.com/deriwfi",
  docs: "https://docs.deriw.com",
  auditReport: "https://skynet.certik.com/projects/deriw-finance",

  sergentPrice: 99, // 100 usdt
  lieutenantPrice: 199, // 300 usdt
  chainName: 'Deriw Testnet',
  SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0005"),
  INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0005"),
  netWorkIcon: "https://oss.deriw.com/chain/",
  ethRpc: "https://rpc.dev.deriw.com",

  mainChainAddr: "0x0000000000000000000000000000000000000000", // 主链币是：BSC
  // mainChainAddr: "0x21007fbfe34972C9B968a4AB1AB94AfdE9170244", // 主链币是：BSC

  address0: "0x0000000000000000000000000000000000000000",
  UnBindReferral: "0x0000000000000000000000000000000000000000000000000000000000000000", // 邀请解除绑定传的地址
  isRepeatCode: "0x0000000000000000000000000000000000000000", // 生成邀请码时校验 等于0地址 邀请码就不重复
  
  Vault: "0xF4D8b2A2Ab4cf85661a83fDd34CBe9f3AA45663C",
  Router: "0x10D1279FfbB4476Af5C45a1A3B107726e6BDE47C",
  VaultUtils: "0x48B2C47CF1A97E9F976dCcBD9e5aba49ab65e4Bf",
  VaultReader: "0x4886271339CDBf1f05182C850Fdb2AD866d54Aee",
  Reader: "0x6781D49D91432f35443E16022d6A47041DdbCD73",
  GlpManager: "0x86050793EE38612E56af3bEb0a2eBD694d6Dfa0B",
  GlpRewardRouter: "0xDddB6E40C052B0F99AC0E1F2b00739D0b8bFB684",
  NATIVE_TOKEN: "0x1BC0e56BF8b0e783F853Da8bC32E2Dea25add376", // (WETH)
  GLP: "0x5b2E016d5d9F73f8B8679FcEc85B4Ce2C8e6022b",
  OrderBook: "0xAd49834Ef6FbF225883837d53A9383cDd069ed9F",
  OrderBookReader: "0x7738689E2a46E4b18b87920a6Ce3807c2c650E33",

  PositionRouter: "0x94b7e109499424717a394bB33f9D42c5052Ca45e",
  ReferralStorage: "0xF2F6c52D4Ea12B28Bce6857BA7583a7b4107E0Dc",
  ReferralData: "0xd874A8414A18C817e93D8c7d169DdA5922a2d32A",
  ReferralReader: "0xfcd5ddd1B944910b94Dc232eA68b6bcd33D5c422",
  Timelock: "0x8D357bF5ae14171a3788dddf7F27DD71597F4a0B",
  Slippage: "0x2aB22De549D45D049bdC2A33461ef5dA81D36159",

  Phase: "0x61D4D7e31688b8D52C68E25EF9dc96d9E80600ab",
  AuthV2: "0xEeB33A17f4afcDcdad6dbf07B4e5ced5179F5046",
  FoundFactoryV2: "0x6011c4c2f10479EC4E17a2595cd2d0F089CC175C",
  PoolDataV2: "0x77817EFbFEdaaac670E1c0aA68C8BAc8F514858d",
  LpCounter: "0xC64E54B42EFF7249F5AfC2Dd25351Ca5511231D2",
  ErrorContractV2: "0xaaB6E464B78eCF05e3962BA9E21e84609a516810",
  FoundRouterV2: "0x3312423eb0718970f260153C49F0787a8f9962Bd",
  FoundReader: "0xb767d32b387e4d6c38a271A864E1F15FF348A95E",
  // 测试币领取
  MINT_AIRDROP: "0xDAD7124fc2A8C90B2446801cA807338537650c4D",
  // Meme资金池
  MemeData: "0xA06DAa3F5FbdC5b84FF676ce5e62634A59BebBec",
  MemeErrorContract: "0x912Ed2406D492b16e41B992eCD4c7A08C571b29A",
  MemeFactory: "0x9B03Af8f671486dDE9709Aee81FFC8747dd54d90",
  MemeRouter: "0x96662b044aD83F3427a6c15eD0e741d9C3eF914f",
  // Hyper 
  DeriwSubAccountPublic: "0x00000000000000000000000000000000000007E9",


  TestAuth: "0x6e6F13D2706D861F539925A5Af18893FB3D1D79C",
  TestPrpoFactory: "0xE9F045f0CE5dc1AD552e20E8df668194d67f95D5",
  TestPropTradeData: "0x305507D45D5441B81F5dD8FF9f00f65e0B392e86",
  TestErrContract: "0x3D343Fc0F6c6D2E047ec5e16e39A9b6A2031B9Ac",
  TestPropTradeRouter: "0x4D778dE09f5C043677bd18888114A9a0911dCE96",
  
  L1ToL2Router: "0x89b80e784F85028C75A00971B1A3f5827DC0d0aa",
  L2ToL3Router: "0xF66e0aBaba7e61968bF31fd75eEb39b379EEC954",
  L3ToL2Router: "0x09167d5D48bd6C95a50cce5E1B163a2017Ea1470",
  L2ToL1Router: "0x37C6A3b3C1E5b64071982dEfd092D8E02eC55118",
  UserL2ToL3Router: "0x610AE987E3C2689E6353c5375c9f3A4d7A129070",
  UserL3ToL2Router: "0x0792cd34d0F8BFC636743A836c62C0bC6810580c",
  ValidateSign: "0x6e465324A7d113389De95183Db8Cfb76d411625c",

  fundMinDeposit: {
    USDT: 10,
    ETH: 0.005,
    BNB: 0.044,
    UNI: 2,
    LINK: 1,
    UNISWAP: 2,
    WBTC: 0.0003,
    PENGU: 10
  }
};
