import { useEffect, useState } from "react";
import { InjectedConnector } from "@web3-react/injected-connector";
import {
  TESTNET,
  DEFAULT_CHAIN_ID,
  getChainName,
  getRpcUrl,
  getRpcUrlExtensionWallet,
  MAINNET,
  NETWORK_METADATA,
  SUPPORTED_CHAIN_IDS,
  DERIWTESTNET,
  ARBITRUM,
  ARBITRUMSEPOLIA,
  DERIWDEVNET,
} from "../../config/chains";
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import useWeb3ReactDeriw from "./useWeb3ReactDeriw";
import {
  CURRENT_PROVIDER_LOCALSTORAGE_KEY,
  SELECTED_NETWORK_LOCAL_STORAGE_KEY,
  SHOULD_EAGER_CONNECT_LOCALSTORAGE_KEY,
  WALLET_CONNECT_V2_LOCALSTORAGE_KEY,
  WALLET_LINK_LOCALSTORAGE_PREFIX,
} from "../../config/localStorage";
import { isMobileDevice, HYPER_PERSIST_TRADING_CONNECTION } from "../legacy"
import { toggleBackDrop } from "../useBackDrop";
import { v4 as uuidv4 } from 'uuid';
import { UserRejectedRequestError, WalletConnectConnector } from "./WalletConnectConnector";
import { toggleNetworkDropdown } from "./useNetworkDropdown";
import { Eip6963ConnectorConnector } from "./Eip6963Connector";
import { Eip6963Providers } from "./useWallet";
import { isEmpty } from "lodash"
import store2 from "store2"

// export type NetworkMetadata = {
//   chainId: string;
//   chainName: string;
//   nativeCurrency: {
//     name: string;
//     symbol: string;
//     decimals: number;
//   };
//   rpcUrls: string[];
//   blockExplorerUrls: string[];
// };

const injectedConnector = new InjectedConnector({
  supportedChainIds: SUPPORTED_CHAIN_IDS,
});

export function hasMetaMaskWalletExtension() {
  //@ts-ignore
  return window.ethereum;
}
export function hasOXKWalletExtension() {
  //@ts-ignore
  return window.okxwallet
}

export function hasCoinBaseWalletExtension() {
  return window.CoinbaseWalletProvider
}

// string
export function activateInjectedProvider(providerName) {
  //@ts-ignore
  const { ethereum } = window;

  if (!ethereum?.providers && !ethereum?.isCoinbaseWallet && !ethereum?.isMetaMask) {
    return undefined;
  }

  let provider;
  if (ethereum?.providers) {
    switch (providerName) {
      case "OKX":
        provider = ethereum.providers.find(({ isCoinbaseWallet }) => isCoinbaseWallet);
        break;
      case "CoinBase":
        provider = ethereum.providers.find(({ isCoinbaseWallet }) => isCoinbaseWallet);
        break;
      case "MetaMask":
      default:
        provider = ethereum.providers.find(({ isMetaMask }) => isMetaMask);
        break;
    }
  }

  if (provider) {
    ethereum?.setSelectedProvider?.(provider);
  }
}

export function getInjectedConnector() {
  return injectedConnector;
}

// rpcMap: : {[MAINNET]: getRpcUrl(MAINNET)!,[TESTNET]: getRpcUrl(TESTNET)!,}
let _WalletConnectConnector
export const getWalletConnectConnector = (isCheck) => {
  if (isCheck && !_WalletConnectConnector) {
    return
  }
  const chainId = Number(localStorage.getItem(SELECTED_NETWORK_LOCAL_STORAGE_KEY)) || DEFAULT_CHAIN_ID;
  if (!_WalletConnectConnector) {
    _WalletConnectConnector = new WalletConnectConnector({
      rpcMap: {
        [MAINNET]: getRpcUrlExtensionWallet(MAINNET),
        [TESTNET]: getRpcUrlExtensionWallet(TESTNET),
        [ARBITRUM]: getRpcUrlExtensionWallet(ARBITRUM),
        [ARBITRUMSEPOLIA]: getRpcUrlExtensionWallet(ARBITRUMSEPOLIA),
        [DERIWTESTNET]: getRpcUrlExtensionWallet(DERIWTESTNET),
        [DERIWDEVNET]: getRpcUrlExtensionWallet(DERIWDEVNET),
      },
      showQrModal: true,
      chainId,
      supportedChainIds: SUPPORTED_CHAIN_IDS,
      projectId: "47bab16dc1a8b52e42e3c7c81bfe197e",
    });
  }
  return _WalletConnectConnector
};

export const getEip6963Connector = (type) => {
  const chainId = Number(localStorage.getItem(SELECTED_NETWORK_LOCAL_STORAGE_KEY)) || DEFAULT_CHAIN_ID;
  // const chainId = Number(localStorage.getItem(SELECTED_NETWORK_LOCAL_STORAGE_KEY)) || DEFAULT_CHAIN_ID;
  return new Eip6963ConnectorConnector({
    rpcMap: {
      [MAINNET]: getRpcUrlExtensionWallet(MAINNET),
      [TESTNET]: getRpcUrlExtensionWallet(TESTNET),
      [ARBITRUM]: getRpcUrlExtensionWallet(ARBITRUM),
      [ARBITRUMSEPOLIA]: getRpcUrlExtensionWallet(ARBITRUMSEPOLIA),
      [DERIWTESTNET]: getRpcUrlExtensionWallet(DERIWTESTNET),
      [DERIWDEVNET]: getRpcUrlExtensionWallet(DERIWDEVNET),
    },
    type: type,
    showQrModal: true,
    chainId,
    supportedChainIds: SUPPORTED_CHAIN_IDS,
    projectId: "47bab16dc1a8b52e42e3c7c81bfe197e",
  });
};

export function clearWalletConnectData() {

  localStorage.removeItem(WALLET_CONNECT_V2_LOCALSTORAGE_KEY);
}

export function clearWalletLinkData() {
  Object.entries(localStorage)
    .map((x) => x[0])
    .filter((x) => x.startsWith(WALLET_LINK_LOCALSTORAGE_PREFIX))
    .map((x) => localStorage.removeItem(x));
}

export const getEip6963Provider = (providerName) => {
  const eip6963Provider = Eip6963Providers.find((provider) => {
    if (provider.id == "io.metamask" && providerName == "MetaMask") {
      return true
    } else if (provider.id == "com.okex.wallet" && providerName == "OKX") {
      return true
    } else if (provider.id == "com.coinbase.wallet" && providerName == "CoinBase") {
      return true
    }
  })

  return eip6963Provider
}

// (connector: any) => void
export function useEagerConnect(setActivatingConnector, silent = false) {
  // const { activate, active } = useWeb3ReactDeriw();
  const { activate, active } = useWeb3React();
  
  const [tried, setTried] = useState(false);
  useEffect(() => {
    (async function () {
      if (Boolean(localStorage.getItem(SHOULD_EAGER_CONNECT_LOCALSTORAGE_KEY)) !== true) {
        // only works with WalletConnect
        clearWalletConnectData();
        // force clear localStorage connection for MM/CB Wallet (Brave legacy)
        clearWalletLinkData();
        return;
      }

      if (silent == true) {
        return
      }

      try {
        // 
        const currentProviderName = localStorage.getItem(CURRENT_PROVIDER_LOCALSTORAGE_KEY) ?? false;
        if (currentProviderName) {

          if (currentProviderName == 'WalletConnect') {
            const connector = new WalletConnectConnector({
              keepLine: true,
              rpcMap: {
                [MAINNET]: getRpcUrlExtensionWallet(MAINNET),
                [TESTNET]: getRpcUrlExtensionWallet(TESTNET),
                [ARBITRUM]: getRpcUrlExtensionWallet(ARBITRUM),
                [ARBITRUMSEPOLIA]: getRpcUrlExtensionWallet(ARBITRUMSEPOLIA),
                [DERIWTESTNET]: getRpcUrlExtensionWallet(DERIWTESTNET),
                [DERIWDEVNET]: getRpcUrlExtensionWallet(DERIWDEVNET),
              },
              showQrModal: false,
              chains: [1],
              optionalChains: [421614, 35318034165],
              projectId: "47bab16dc1a8b52e42e3c7c81bfe197e",
            });
            if (connector) {
              setActivatingConnector(connector);
              await activate(connector, undefined, true);
              return;
            }
          } else {
            const eip6963Connector = getEip6963Provider(currentProviderName);
            let connector = getInjectedConnector();
            if (eip6963Connector) {
              connector = getEip6963Connector(eip6963Connector.id)
            }
            if (currentProviderName !== false) {
              activateInjectedProvider(currentProviderName);
            }
            const authorized = await connector.isAuthorized();
            if (authorized) {
              setActivatingConnector(connector);
              await activate(connector, undefined, true);
            }
          }
        }
      } catch (ex) { }

      // try {
      //   // const connector = getWalletConnectConnector(true);
      //   const connector = new WalletConnectConnector({
      //     keepLine: true,
      //     rpcMap: {
      //       [MAINNET]: getRpcUrlExtensionWallet(MAINNET),
      //       [TESTNET]: getRpcUrlExtensionWallet(TESTNET),
      //       [ARBITRUM]: getRpcUrlExtensionWallet(ARBITRUM),
      //       [ARBITRUMSEPOLIA]: getRpcUrlExtensionWallet(ARBITRUMSEPOLIA),
      //       [DERIWTESTNET]: getRpcUrlExtensionWallet(DERIWTESTNET),
      //       [DERIWDEVNET]: getRpcUrlExtensionWallet(DERIWDEVNET),
      //     },
      //     showQrModal: false,
      //     chains: [1],
      //     optionalChains: [2884, 35318034165],
      //     projectId: "47bab16dc1a8b52e42e3c7c81bfe197e",
      //   });
      //   if (connector) {
      //     setActivatingConnector(connector);
      //     await activate(connector, undefined, true);
      //     return;
      //   }
      //   // in case Wallet Connect is activated no need to check injected wallet
      // } catch (ex) {
      //   // assume data in localstorage is corrupted and delete it to not retry on next page load
      // }

      setTried(true);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // intentionally only running on mount (make sure it's only mounted once :))

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (!tried && active) {
      setTried(true);
    }
  }, [tried, active]);
  return tried;
}

export function useInactiveListener(suppress = false) {
  const injected = getInjectedConnector();
  // const { active, error, activate } = useWeb3ReactDeriw();
  const { active, error, activate } = useWeb3React();

  useEffect(() => {
    //@ts-ignore
    const { ethereum } = window;
    if (ethereum && ethereum.on && !active && !error && !suppress) {
      const handleConnect = () => {
        activate(injected);
      };
      const handleChainChanged = (_) => {
        activate(injected);
      };
      const handleAccountsChanged = (accounts) => {
        if (accounts.length > 0) {
          activate(injected);
        }
      };
      const handleNetworkChanged = (_) => {
        activate(injected);
      };

      ethereum.on("connect", handleConnect);
      ethereum.on("chainChanged", handleChainChanged);
      ethereum.on("accountsChanged", handleAccountsChanged);
      ethereum.on("networkChanged", handleNetworkChanged);

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener("connect", handleConnect);
          ethereum.removeListener("chainChanged", handleChainChanged);
          ethereum.removeListener("accountsChanged", handleAccountsChanged);
          ethereum.removeListener("networkChanged", handleNetworkChanged);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, error, suppress, activate]);
}

export const addBscNetwork = async () => {
  return addNetwork(NETWORK_METADATA[MAINNET]);
};

// metadata: NetworkMetadata
export const addNetwork = async (metadata) => {
  // @ts-ignore
  try {
    const walletConnect = getWalletConnectConnector(true);
    walletConnect.walletConnectProvider.request({ method: "wallet_addEthereumChain", params: [metadata] })
    return
  } catch (ex) {
    // assume data in localstorage is corrupted and delete it to not retry on next page load
  }

  const currentProviderName = localStorage.getItem(CURRENT_PROVIDER_LOCALSTORAGE_KEY) ?? false;
  const eip6963Connector = getEip6963Provider(currentProviderName);
  if (eip6963Connector) {
    try {
      await eip6963Connector.request({ method: "wallet_addEthereumChain", params: [metadata] })
    } catch (ex) {

    }
  } else {
    try {
      await window.ethereum.request({ method: "wallet_addEthereumChain", params: [metadata] })
    } catch (ex) {
      console.error("error", ex)
    }
  }
};

const uid = uuidv4()
// chainId: number, _?: boolean
export const switchNetwork = async (chainId, active) => {
  if (!active) {
    // chainId in localStorage allows to switch network even if wallet is not connected
    // or there is no wallet at all
    localStorage.setItem(SELECTED_NETWORK_LOCAL_STORAGE_KEY, String(chainId));
    return;
  }

  // 切换网络更改本地存储的CHAINID
  // localStorage.setItem(SELECTED_NETWORK_LOCAL_STORAGE_KEY, String(chainId));
  try {
    const chainIdHex = "0x" + chainId.toString(16);
    // @ts-ignore
console.log('%c chainId', 'color: #00A0E9; font-size: 26px; font-weight: blod;', chainId);
    try {
      const walletConnect = getWalletConnectConnector(true);
      await walletConnect.walletConnectProvider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chainIdHex }],
      })
      return
    } catch (ex) {
      console.log('%c 6ex', 'color: #00A0E9; font-size: 26px; font-weight: blod;', ex);
    }
console.log('%c chainIdHex', 'color: #00A0E9; font-size: 26px; font-weight: blod;', chainIdHex);
    const currentProviderName = localStorage.getItem(CURRENT_PROVIDER_LOCALSTORAGE_KEY) ?? false;
    const eip6963Connector = getEip6963Provider(currentProviderName);
    if (eip6963Connector) {
      console.log('%c 7e', 'color: #00A0E9; font-size: 26px; font-weight: blod;', eip6963Connector);
      try {
        await (eip6963Connector.request || eip6963Connector.provider.request)({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: chainIdHex }],
        })
      } catch (ex) {
        console.log('%c 7ex', 'color: #00A0E9; font-size: 26px; font-weight: blod;', ex);
        if (ex.code !== 4001) {
          const netData = JSON.parse(JSON.stringify(NETWORK_METADATA[chainId]))
          delete netData['nativeTokenSymbol']
          delete netData['defaultCollateralSymbol']
          delete netData['SWAP_ORDER_EXECUTION_GAS_FEE']
          delete netData['INCREASE_ORDER_EXECUTION_GAS_FEE']
          delete netData['DECREASE_ORDER_EXECUTION_GAS_FEE']
          return await addNetwork(netData);
        }
      }
    } else {
      console.log('%c 8e', 'color: #00A0E9; font-size: 26px; font-weight: blod;', );
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: chainIdHex }],
        });
      } catch (ex) {
        console.log('%c 8ex', 'color: #00A0E9; font-size: 26px; font-weight: blod;', );
      }
    }


    toggleNetworkDropdown({ id: "WebNetworkDropdownModal", isShow: false })
    toggleBackDrop({ id: "Wallet", isShow: true })
    console.error(`Connected to ${getChainName(chainId)}`)
    // toast.custom(
    //   (c) => {
    //     return <EventToast
    //       event={{ etype: Info, id: uid, title: `Connected to ${getChainName(chainId)}`, bodyText: [], buttons: [] }}
    //       id={uid}
    //       t={c}
    //       onClick={() => {
    //         toast.dismiss(uid)
    //         toggleBackDrop({ id: "Wallet", isShow: false })

    //         return {}
    //       }}
    //     />
    //   },
    //   {
    //     id: uid,
    //     style: {},
    //   }
    // );
    return getChainName(chainId);
  } catch (ex) {
    // debugger
    console.log('%c ex9', 'color: #00A0E9; font-size: 26px; font-weight: blod;', ex);
    // https://docs.metamask.io/guide/rpc-api.html#other-rpc-methods
    // This error code indicates that the chain has not been added to MetaMask.
    // 4001 error means user has denied the request
    // If the error code is not 4001, then we need to add the network
    // localStorage.setItem(SELECTED_NETWORK_LOCAL_STORAGE_KEY, currentNetwork);
    if (ex.code !== 4001) {
      const netData = JSON.parse(JSON.stringify(NETWORK_METADATA[chainId]))
      delete netData['nativeTokenSymbol']
      delete netData['defaultCollateralSymbol']
      delete netData['SWAP_ORDER_EXECUTION_GAS_FEE']
      delete netData['INCREASE_ORDER_EXECUTION_GAS_FEE']
      delete netData['DECREASE_ORDER_EXECUTION_GAS_FEE']
      return await addNetwork(netData);
    }

    // eslint-disable-next-line no-console
    console.error("ex5", ex);
  }
};

// activate: Web3ReactManagerFunctions["activate"],deactivate: Web3ReactManagerFunctions["deactivate"],setActivatingConnector: (connector?: WalletConnectConnector) => void
export const getWalletConnectHandler = (activate, deactivate, setActivatingConnector) => {
  const fn = async () => {
    const walletConnect = getWalletConnectConnector();
    setActivatingConnector(walletConnect);
    activate(walletConnect, (ex) => {
      if (ex instanceof UnsupportedChainIdError) {
        // helperToast.error(`Unsupported chain. Switch to Arbitrum network on your wallet and try again`);
        console.error(`Unsupported chain.Switch to Arbitrum network on your wallet and try again`)
        // eslint-disable-next-line no-console
        console.warn(ex);
      } else if (!(ex instanceof UserRejectedRequestError)) {
        // helperToast.error(ex.message);
        console.error(ex.message);
        // eslint-disable-next-line no-console
        console.warn(ex);
      }
      clearWalletConnectData();
      deactivate();
    });
  };
  return fn;
};

// activate: Web3ReactManagerFunctions["activate"], deactivate: Web3ReactManagerFunctions["deactivate"]
export const getInjectedHandler = (activate, deactivate, eipType) => {
  const fn = async () => {
    const connector = getEip6963Connector(eipType);
    activate(
      eipType != "" ? connector : getInjectedConnector(), (e) => {
        if (e instanceof UnsupportedChainIdError) {
          showUnsupportedNetworkToast();

          deactivate();

          const netData = JSON.parse(JSON.stringify(NETWORK_METADATA[SUPPORTED_CHAIN_IDS.at(0)]))
          delete netData['nativeTokenSymbol']
          delete netData['defaultCollateralSymbol']
          delete netData['SWAP_ORDER_EXECUTION_GAS_FEE']
          delete netData['INCREASE_ORDER_EXECUTION_GAS_FEE']
          delete netData['DECREASE_ORDER_EXECUTION_GAS_FEE']
          return addNetwork(netData);
        }

        const errString = e.message ?? e.toString();
        // helperToast.error(errString);
        console.error(errString);
      });
  };
  return fn;
};


// {address: string;symbol: string;decimals: number;imageUrl ?: string;}
export async function addTokenToMetamask(token) {
  try {
    // @ts-ignore
    const wasAdded = await window.ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20",
        options: {
          address: token.address,
          symbol: token.symbol,
          decimals: token.decimals,
          image: token.imageUrl,
        },
      },
    });
    if (wasAdded) {
      // https://github.com/MetaMask/metamask-extension/issues/11377
      // We can show a toast message when the token is added to metamask but because of the bug we can't. Once the bug is fixed we can show a toast message.
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export function showUnsupportedNetworkToast() {
  const chainId = Number(localStorage.getItem(SELECTED_NETWORK_LOCAL_STORAGE_KEY)) || DEFAULT_CHAIN_ID;

  const uid = uuidv4()
  toggleBackDrop({ id: "WalletError", isShow: true })
  console.error(`Your wallet is not connected to ${getChainName(chainId)}.`)
  setTimeout(() => {
    switchNetwork(chainId, true)
  }, 2000)
  // toast.custom(
  //   (c) => {
  //     return <EventToast
  //       event={{
  //         etype: Warning,
  //         id: uid,
  //         title: `Your wallet is not connected to ${getChainName(chainId)}.`,
  //         bodyText: [],
  //         buttons: [
  //           {
  //             text: `Switch to ${getChainName(chainId)}`,
  //             action: function () {
  //               switchNetwork(chainId, true)
  //               toast.dismiss(uid)
  //               toggleBackDrop({ id: "WalletError", isShow: false })
  //             },
  //           }
  //         ]
  //       }}
  //       id={uid}
  //       t={c}
  //       onClick={() => {
  //         toast.dismiss(uid)
  //         toggleBackDrop({ id: "WalletError", isShow: false })
  //         return {}
  //       }}
  //     />
  //   },
  //   {
  //     id: uid,
  //     style: {},
  //   }
  // );
}

export function useHandleUnsupportedNetwork() {
  // const { error, deactivate } = useWeb3ReactDeriw();
  const { error, deactivate } = useWeb3React();

  useEffect(() => {
    if (error instanceof UnsupportedChainIdError) {
      showUnsupportedNetworkToast();

      deactivate();
    }
  }, [error, deactivate]);
}

export const userOnMobileDevice = "navigator" in window && isMobileDevice(window.navigator);


/**
 * @description 获取当前的子钱包信息
 * @param {string} account - 用户账户地址
 * @param {string} userAddress - 父账户地址
 * @returns {Object} connectedSubWallet - 当前连接的子钱包信息
 */
export function getConnectedSubWallet(account) {
  if (!account) return {};
  
  const HYPER_USER_CONNECTION = `deriw_agent_${account}`;
  const sessionConnect = store2.session.get(HYPER_USER_CONNECTION) || {};
  const localConnect = store2.get(HYPER_USER_CONNECTION) || {};
  const localTradingConnection = store2.get(HYPER_PERSIST_TRADING_CONNECTION);

  if (!isEmpty(localConnect) && localTradingConnection) {
      return localConnect;
  }

  if (!isEmpty(sessionConnect)) {
      return sessionConnect;
  }

  return {};
}

/**
 * @description 获取本地存储的用户地址
 * @returns {string} 用户地址
 */
export function getUserAddress() {
    return store2.get('userAddress') || '';
}