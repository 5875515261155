import React, { useMemo, useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import ConnectionModal from '../components/ConnectionModal'
import { setConnectionModal } from "../store/subAccount/action";

const useSubAccount = () => {
    const { isSubConnected } = useSelector(state => state.subAccount)
    const dispatch = useDispatch();

    const isSubAccountCheck = () => {
        console.log("🚀 ~ useSubAccount ~ isSubConnected:", isSubConnected)
        if (!isSubConnected) {
            dispatch(setConnectionModal({isConnectionModal:true})) 
        }
    };

    return {
        isSubConnected,
        isSubAccountCheck,
    };
};

export default useSubAccount;